@font-face {
    font-family: "Tungsten";
    src: url('./Tungsten-Medium.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "MyriadPro";
    src: url('./MyriadPro-Semibold.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SpaceMono";
    src: url('./SpaceMono-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SpaceMono";
    src: url('./SpaceMono-Bold.ttf') format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url('./Roboto-Bold.ttf') format("truetype");
    font-weight: bold;
    font-style: normal;
}