html,
body {
  overflow: hidden;
}

* {
  user-select: none;
  font-size: 1vw
}

.option_hover {
  transition: background-color 0.2s ease,
}

.option_hover:hover {
  color: "#000";
  background-color: #00B28F;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}